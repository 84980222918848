import { h, Fragment } from 'preact'
import { styled } from 'goober'
import { SocialShortcuts } from './social'
import { Link } from '../utils/location'


const FooterTag = styled('footer')`
  color: rgb(96, 96, 96);
  padding: 0.5em;
  margin-bottom: 0.5em;
  clear: both;
  &::before {
    margin: 0 15%;
    border-top: 1px solid rgb(128, 128, 128);
    content: " ";
    display: block;
    margin-bottom: 1em;
  }
`

export function Footer() {
  return <FooterTag>
    <SocialShortcuts />
    <FooterLinks />
  </FooterTag>
}

const Links = styled('ul')`
  margin: 0;
  padding: 0;
  text-align: center;
  & li {
    margin: 0;
    padding: 0;
    display: inline;
    & + li::before {
      content: " - ";
      color: #999;
    }
  }
  & a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-shadow: 0 0 1px currentColor;
    }
  }
`
const UniqueLink = styled('div')`
  margin: 0;
  padding: 0;
  text-align: center;
  & a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-shadow: 0 0 1px currentColor;
    }
    &:visited {
      color: inherit;
    } 
  }
`
function FooterLinks() {
  return <>
    <Links>
      <li><Link as='a' href="/">⌂ Projets</Link></li>
      <li><Link as='a' href="/p/participer">Participer</Link></li>
      <li><Link as='a' href="/t/contact">Contact</Link></li>
      <li><Link as='a' href="/t/legal">Légal</Link></li>
    </Links>
    <UniqueLink><Link as='a' href="/c/par-publication">Dernières publications</Link></UniqueLink>
  </>
}