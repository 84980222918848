import { h, Fragment } from 'preact'
import { useMemo, useCallback, useEffect } from 'preact/hooks'
import { styled } from 'goober'
import { Link, setLocation, useLocation } from '../utils/location'
import { useKeydown } from '../utils/keyboard'
import { getGalleryPathesFromRatio, useGalleryNavigation, showGallery } from './gallery'
import { prefetchElement } from './image'
import { useOnSwipe } from '../utils/swipe'
import { executeOneQueryOf, executeQuery } from '../utils/query'
import { useWindowSize } from '../utils/window_size'
import { contentDataUrl } from '../utils/content'

const prefetchTimeout = 100

const Base = styled('a')`
font-family: "Avenir Next","Monserrat Thin","Monserrat","Roboto Thin","Roboto","Helvetica Neue","Segoe UI Light","Segoe UI","Lato Thin","Lato Thin","Corbel Light","Fira Sans Thin","Fira Sans Extra-Light","Open Sans Light","Droid Sans Light", sans-serif;
font-weight: 200;
text-decoration: none;
color: inherit;
text-decoration: none;
line-height: 1.75;
&:hover {
  text-shadow: var(--hoverShadow, 0 0 1px currentColor);
}
padding-left: 0.33em;
padding-right: 0.5em;
`
const A = styled(Base)`
float: left;
font-size: 2.5em;  
`

const emptyState = {}
const normalStyle = { width: '100%' }
const returnStyle = { color: 'rgb(96,96,96)', width: '100%' }
export function Header({style, current}={}) {
  const {state} = useLocation()
  const from = state?.from
  const context = from?.location
  const url = context ? (context + `#${current.url.slice(1)}`) : '/'
  const title = state?.from?.title || 'Accueil'
  const text = context ? "⇈" : "⌂" // ╳ ↩︎↩↼⟵⤶^⌌#⌑⌌⌧⍃⍅⍙⇈⎕↰
  const shows = from?.shows
  const newState = useMemo(() => context ? {shows} : emptyState, [context, shows])
  const onKeydown = useCallback(
    () => {
      setLocation({url, title})
    }, [url, title]
  )
  useKeydown(onKeydown, { key: "Escape", disabled: !state.from })
  return <header style={style || (context ? returnStyle : normalStyle)}>
    <Link as={A} href={url} state={newState} title={title}>{text}</Link>
    {from && <Navigation from={from} current={current}/>}
  </header>
}

export function ImageHeader({color, background, current}) {
  const style = useMemo( () => ({
    position: 'absolute',
    top: 0,
    left: 0,
    color: color || 'black',
    fontSize: '0.5em',
    width: '100%',
    textShadow: `0 0 2px ${background || 'white'}`,
    '--hoverShadow': `0 0 2px currentColor, 0px 0 4px ${background || 'white'}`,
  }), [color])
  return Header({style, current})
}

const homeStyle = {position: 'absolute', top: 0, left: 0}
export function HomeHeader() {
  return Header({style: homeStyle})
}



const NextOrPreviousTag = styled(Base)`
position: absolute;
top: calc(0.5 * var(--vh, 100vh) - (1.5 * 1.75rem + 2em) / 2);
font-size: 1.5rem;
padding-top: 1em;
padding-bottom: 1em;
`
const TextNextOrPreviousTag = styled(NextOrPreviousTag)`
@media (max-width: 34em) {
  padding-left: 0;
  padding-right: 0; 
}
`

const previousStyle = {left: 0}
const nextStyle = {right: 0}

function NextOrPrevious({current, target, currentGallery, from, isNext}) {
  const navigate = useCallback(
    () => {
      setLocation({url: target.url, from, title: target.title})
    }, [current, target, from]
  )
  useKeydown(navigate, { key: isNext ? "ArrowRight" : "ArrowLeft", disabled: !target })
  useOnSwipe({ onLeft: isNext && navigate, onRight: !isNext && navigate, disabled: !target })
  const Component = (current.type === 'text') ? TextNextOrPreviousTag : NextOrPreviousTag
  const style = isNext ? nextStyle : previousStyle
  const text = isNext ? "⟩" : "⟨"
  const query = getGalleryQuery(from.context, currentGallery)
  useEffect(() => {
    if (isNext && target) {
      let cancelled = false
      let image 
      const up = async () => {
        if (!cancelled) {
          executeQuery(contentDataUrl(target.url))
          const gallery = await query()
          const slot = gallery.contents.find(c => c.url === target.url)
          if (!cancelled && slot && (slot.url[1] === 'i')) {
            image = prefetchElement({url: slot.url, width: slot.width, height: slot.height})
          }
        }
      }
      window.setTimeout(up, prefetchTimeout)
      return () => {
        cancelled = true
        image = undefined
      }
    }
  }, [target.url])
  return target && <Link as={Component} href={target.url} style={style} from={from} title={target.title}>{text}</Link>
}

function getGalleryQuery(context, name) {
  const { innerRatio: ratio } = useWindowSize()
  return useMemo(() => {
    const pathes = getGalleryPathesFromRatio(context, name, ratio)
    return () => executeOneQueryOf(pathes)
  }, [context, name, ratio])
}

function changeGallery(name, from) {
  const shows = showGallery({state: from, context: from?.context, next: name})
  return {...from, shows, gallery: name}
}


function NextOrPreviousGallery({current, target, from, isNext}) {
  const name = target
  const newFrom = useMemo(() => changeGallery(name, from), [target, from])
  const context = newFrom.context
  const query = getGalleryQuery(context, name)
  const navigate = useCallback(
    async (event) => {
      event && event.preventDefault && event.preventDefault()
      const gallery = await query()
      const contents = gallery?.contents
      if (contents) {
        const following = isNext ? contents?.[0] : contents[contents.length - 1]
        const url = following?.url
        if (url) {
          const title = following?.title 
          setLocation({url, from:newFrom, title})
        } else {
          console.error(isNext ? "Fail to go to next image" : "Faile to go to previous image", {current, target, from})
        }
      }
    }, [query, newFrom, isNext]
  )
  useEffect(() => {
    if (isNext && target) {
      let cancelled = false
      let image 
      const up = async () => {
        if (!cancelled) {
          const gallery = await query()
          const contents = gallery?.contents
          if (!cancelled && contents) {
            const following = isNext ? contents?.[0] : contents[contents.length - 1]
            const url = following?.url
            if (url && (url[1] === 'i')) {
              executeQuery(contentDataUrl(url))
              image = prefetchElement({url: url, width: following.width, height: following.height})
            }
          }
        }
      }
      window.setTimeout(up, prefetchTimeout)
      return () => {
        cancelled = true
        image = undefined
      }
    }
  }, [target.url, isNext])
  useKeydown(navigate, { key: isNext ? "ArrowRight" : "ArrowLeft", disabled: !target })
  useOnSwipe({ onRight: !isNext && navigate, onLeft: isNext && navigate, disabled: !target })
  const Component = (current.type === 'text') ? TextNextOrPreviousTag : NextOrPreviousTag
  const style = isNext ? nextStyle : previousStyle
  const text = isNext ? "⟫" : "⟪"
  const label = isNext ? "Gallerie suivante" : "Gallerie précédente"
  return target && <Link onClick={navigate} as={Component} href={`${context}#${current.url.slice(1)}`} style={style} from={newFrom} title={label}>{text}</Link>
}

function Navigation({from, current}) {
  const context = from?.context
  const name = from?.gallery
  const galleries = from?.galleries
  const { previous, next, previousGallery, nextGallery } = useGalleryNavigation({ context, name, current, galleries })
  return <>
    {previous && <NextOrPrevious current={current} target={previous} from={from} isNext={false} />}
    {!previous && previousGallery && <NextOrPreviousGallery current={current} target={previousGallery} from={from} isNext={false}/>}
    {next && <NextOrPrevious current={current} currentGallery={name} target={next} from={from} isNext={true} />}
    {!next && nextGallery && <NextOrPreviousGallery current={current} currentGallery={name} target={nextGallery} from={from} isNext={true}/>}
  </>
} 