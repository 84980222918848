import { useEffect, useRef } from 'preact/hooks'
const minLength = 30
const minVelocity = 0.3
const maxTime = 2000

function getDirection({x, y}) {
  const length = Math.sqrt(x*x + y*y)
  const angle = Math.acos(x / length)
  if (angle < 0.4) return 'right'
  if (angle > 3.14 - 0.4) return 'left'
  return false
}

export function useOnSwipe({target, onLeft, onRight, disabled}) {
  const base = target?.current || target || window
  const threeshold = 10
  const ref = useRef({})
  useEffect(() => {
    if (!disabled) {
      const onEnd = (event) => {
        const date = Date.now()
        const touch = event.changedTouches[0]
        if (touch.identifier != ref.current?.identifier) {
          return
        }
        const time = date - ref.current?.date
        if (time > maxTime) {
          return
        }
        const x = touch.screenX - ref.current.screenX
        if (Math.abs(x) < minLength) { 
          return
        }
        const y = Math.abs(touch.screenY - ref.current.screenY)
        const direction = getDirection({x, y})
        if (!direction) {
          return 
        }
        const velocity = Math.abs(x) / time
        if (velocity < minVelocity) {
          return
        }
        const res = { x, y, velocity, time, direction, event }
        if (direction == 'left') onLeft && onLeft(res)
        if (direction == 'right') onRight && onRight(res)
        ref.current = {}
      }
      const onStart = (event) => {
        const touch = event.changedTouches[0]
        ref.current = { identifier: touch.identifier, screenX: touch.screenX, screenY: touch.screenY, date: Date.now() }
      }
      base.addEventListener('touchstart', onStart, false)
      base.addEventListener('touchend', onEnd, false)
      return () => {
        base.removeEventListener('touchstart', onStart)
        base.removeEventListener('touchend', onEnd)
      }
    }
  }, [target, onLeft, onRight, disabled])
}