if (process.env.NODE_ENV==='development') {
  require("preact/debug");
}
import { h, render, Fragment} from 'preact'
import { setup, glob} from 'goober'
import { useErrorBoundary } from 'preact/hooks'
import ContentPage from './pages/content'
import Error404Page from './pages/error404'
import ErrorPage from './pages/error'
import { WithWindowSize, setBarHeight } from './utils/window_size'
import { LocationContext, useLocation } from './utils/location'


if (!window.cacheid) {
  console.warn("I didn't found the cacheid, generating one")
  window.cacheid = (Date.now()).toString(36)
} 
console.debug("CacheId", window.cacheid)

setup(h)

glob`
  html {
    --rem-size: 1;
    font-size: calc(100% * var(--rem-size));
  }
  @media (min-width: 50em) {
    html {
      --rem-size: 1.1;
    }
  }
  @media (min-width: 60em) {
    html {
      --rem-size: 1.2;
    }
  }
  @media (min-width: 70em) {
    html {
      --rem-size: 1.3;
    }
  }
  @media (min-width: 80em) {
    html {
      --rem-size: 1.4;
    }
  }
  @media (min-width: 90em) {
    html {
      --rem-size: 1.5;
    }
  }
  @media (min-width: 100em) {
    html {
      --rem-size: 1.6;
    }
  }
`

function ErrorHandler({children}) {
  const [error, resetError] = useErrorBoundary(error => console.error("Error Boundary", error))
  if (error) {
    return <><ErrorPage error={error} resetError={resetError} /></>
  } else {
    return children
  }
}

function Context({children, bar, height, width}) {
  return <LocationContext><WithWindowSize bar={bar} height={height} width={width}><ErrorHandler>{children}</ErrorHandler></WithWindowSize></LocationContext>
}

function App() {
  // TODO manage redirects
  const { location } = useLocation()
  const pathname = location.pathname
  return <ContentPage url={pathname} />
}

const { height, width, bar } = setBarHeight()
render(<Context bar={bar} height={height} width={width}><App /></Context>, document.body)