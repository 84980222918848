import { h, Fragment } from 'preact'
import { forwardRef } from 'preact/compat'
import { useEffect, useRef } from 'preact/hooks'
import { styled } from 'goober'
import { convertLinks, Link } from '../utils/location'
import { NamedGalleries } from './gallery'
import { Image } from './image'
import { Video } from './video'
import { useWindowSize } from '../utils/window_size'

const Section = styled('div', forwardRef)`
  & a {
    color: rgb(0,0,128);
    text-decoration: underline dotted;
    &:visited {
      color: rgb(64,0,64);
    }
    &:hover {
      text-shadow: 0 0 1px currentColor;
    }
  }
  & div hr {
    visibility: hidden;
    &::after {
      visibility: visible;
      content: "⁂";
      margin: auto;
      text-align: center;
      display: block;
    }
  }
  & div blockquote {
    margin: 0;
    padding: 0 0 0 0.75em;
    border-left: 0.75em solid rgb(240, 240, 240);
    font-style: italic;
  }
`

export default function TextContent({text}) {
  const section = useRef(null)
  useEffect(() => {
    if (section?.current && text) {
      const links = section.current.querySelectorAll('a[href^="/"]')
      return convertLinks(links)
    }
  }, [text])
  
  return <Section ref={section}>{text?.map(
    t => {
      if (t.type==='text') {
        return <div dangerouslySetInnerHTML={{__html: t.html}} />
      } else if (t.type==='gallery') { 
        return <FigureGalleries context={t.context} names={t.galleries} url={t.url} title={t.title} />
      } else if (t.type==='image') {
        
        return <FigureImage content={t.context} title={t.title} width={t.width} height={t.height} url={t.url} />
      } else if (t.type==='video') {
        return <FigureVideo content={t.context} title={t.title} width={t.width} height={t.height} url={t.url}  />
      }
    }
  )}</Section>
}


const Figure = styled('figure')`
  margin: 1em 0;
  padding: 0;
  & figcaption {
    font-style: italic;
  }
`
function ContentFigure({url, title, children}) {
  return <Figure>{children}{title && <figcaption>{url ? <Link href={url}>{title}</Link> : title}</figcaption>}</Figure>
}

const imageStyle ={ width: '100%' }
const sizes = `(min-width: 100em) ${(32 * 16 * 1.6).toFixed(3)}px, (min-width: 90em) ${(32 * 16 * 1.5).toFixed(3)}px, (min-width: 80em) ${(32 * 16 * 1.4).toFixed(3)}px, (min-width: 70em) ${(32 * 16 * 1.3).toFixed(3)}px, (min-width: 60em) ${(32 * 16 * 1.2).toFixed(3)}px, (min-width: 50em) ${(32 * 16 * 1.1).toFixed(3)}px, (min-width: 34em) ${(32 * 16 * 1.0).toFixed(3)}px, 100vw` // 32rem
export function FigureImage({content, title, width, height, url}) {
  url = url ?? content
  return <ContentFigure title={title} url={url}>
    <Image content={content} title={title} width={width} height={height} sizes={sizes} style={imageStyle} />
  </ContentFigure>
}

function getAvailableWidth(innerWidth) {
  if (innerWidth > 100 * 16) return 32 * 16 * 1.6
  if (innerWidth > 90 * 16) return 32 * 16 * 1.5
  if (innerWidth > 80 * 16) return 32 * 16 * 1.4
  if (innerWidth > 70 * 16) return 32 * 16 * 1.3
  if (innerWidth > 60 * 16) return 32 * 16 * 1.2
  if (innerWidth > 50 * 16) return 32 * 16 * 1.1
  if (innerWidth > 34 * 16) return 32 * 16 * 1.0
  return innerWidth
}

export function FigureVideo({content, title, width, height, url}) {
  url = url ?? content
  const { innerWidth, innerHeight } = useWindowSize()
  const availableWidth = getAvailableWidth(innerWidth)
  const availableHeight = innerHeight * 0.95
  return <ContentFigure title={title} url={url}>
    <Video 
      content={content} title={title} url={url} 
      width={width} height={height} 
      availableWidth={availableWidth} availableHeight={availableHeight}
    />
  </ContentFigure>
}


const gallerySizes = (ratio) => `(min-width: 100em) ${(ratio * 32 * 16 * 1.6).toFixed(3)}px, (min-width: 90em) ${(ratio * 32 * 16 * 1.5).toFixed(3)}px, (min-width: 80em) ${(ratio * 32 * 16 * 1.4).toFixed(3)}px, (min-width: 70em) ${(ratio * 32 * 16 * 1.3).toFixed(3)}px, (min-width: 60em) ${(ratio * 32 * 16 * 1.2).toFixed(3)}, (min-width: 50em) ${(ratio * 32 * 16 * 1.1).toFixed(3)}, (min-width: 34em) ${(ratio * 32 * 16).toFixed(3)}px, ${(ratio * 100).toFixed(3)}vw`
export function FigureGalleries({context, names, title, url}) {
  return <ContentFigure title={title} url={url}>
    <NamedGalleries context={context} names={names} sizes={gallerySizes} />
  </ContentFigure>
}