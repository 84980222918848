import { h, Fragment } from 'preact'
import { useCallback, useMemo } from 'preact/hooks'
import { useQuery } from '../utils/query'
import { styled } from 'goober'
import useContent from '../utils/content'
import { useWindowSize } from '../utils/window_size'
import { Link, useLocation, getLocationState, replaceLocation } from '../utils/location'
import { Image } from './image'

const SLOTS = {
  'photo': GalleryPhoto,
  'video': GalleryVideo,
  'text': GalleryText,
  'pdf': GalleryText,
  'collection': GalleryCollection,
  'photographe': GalleryCollection,
  'project': GalleryProject,
  'series': GallerySeries,
  'home': GalleryHome,
  'vsplit': GalleryVSplit,
  'hsplit': GalleryHSplit,
}

const emptyStyle = {}
const hiddentStyle = { display: 'none' }
const defaultSizes = (ratio) =>(ratio * 100).toFixed(3) + 'vw'

function GallerySlot({slot, from, baseWidth, baseSpacing, parentWidth, sizes}) {
  parentWidth = parentWidth || baseWidth
  const style = {
    width: (slot.width / parentWidth * 100).toFixed(3) + '%',
  }
  const GalleryComponent = SLOTS[slot.type]
  return <GalleryComponent from={from} slot={slot} style={style} baseWidth={baseWidth} baseSpacing={baseSpacing} parentWidth={parentWidth} sizes={sizes} />
}


export function FullWidthGalleries({context, sizes=defaultSizes}) {
  return <Galleries context={context} sizes={sizes} />
}

export function Galleries({context, sizes=defaultSizes}) {
  const { content } = useContent(context)
  return content?.galleries && <NamedGalleries context={context} names={content?.galleries} sizes={sizes} />
}

export function fullGalleryName({context, name}) {
  return `${context}#${name}`
}

export function hideGallery({state: previous, context, next}) {
  next = Array.isArray(next) ? next : [next]
  const names = next.map(name => fullGalleryName({context, name}))
  return (previous.shows || []).filter(n => !names.includes(n))
}

export function hideGalleryState({context, next}) {
  const state = getLocationState()
  const shows = hideGallery({context, next, state: state})
  return {...state, shows}
}

export function showGallery({state: previous, context, next}) {
  next = Array.isArray(next) ? next[0] : next
  const name = fullGalleryName({context, name: next})
  const shows = previous.shows || []
  return shows.includes(name) ? shows : shows.concat([name])
}

export function showGalleryState({context, next}) {
  const state = getLocationState()
  const shows = showGallery({context, next, state})
  return {...state, shows}
}

export function isGalleryShown({context, name, state}) {
  return (state?.shows || []).includes(fullGalleryName({context, name}))
}

const Button = styled('button')`
  display: block;
  margin: 0.5rem;
  padding: 0;
  border: none; 
  color: rgb(128,128,128);
  font-size: 1em;
  background-color: inherit;
  border-radius: 0.25em;
  line-height: 1em;
  cursor: pointer;
`
function HideNext({context, current, next, hide=false}) {
  const onClick = useCallback(
    () => {
      replaceLocation({state: hideGalleryState({context, next})})
    }, [current]
  )
  const style = hide ? hiddentStyle : emptyStyle
  return <Button style={style} onClick={onClick}>(⎗ replier la suite de cette galerie)</Button>
}

function ShowNext({context, next, hide=false}) {
  const onClick = useCallback(
    () => {
      replaceLocation({state: showGalleryState({context, next})})
    }, [next]
  )
  const style = hide ? hiddentStyle : emptyStyle
  return <Button style={style} onClick={onClick}>(voir la suite de cette galerie ⎘)</Button>
}

export function NamedGalleries({context, names, sizes=defaultSizes}) {
  const { state } = useLocation()
  const length = names?.length
  return names?.map(
    (name, i) => {
      const display = i === 0 || isGalleryShown({context, name, state})
      if (display) {
        const next = names[i + 1]
        const hasNext = !!next
        const displayNext = hasNext && isGalleryShown({context, name: names[i + 1], state})
        const ButtonComponent = displayNext ? HideNext : ShowNext
        const button = hasNext && <ButtonComponent context={context} next={names.slice(i+1)} current={name} hide={!display} />
        return <><Gallery context={context} galleries={names} name={name} sizes={sizes} hide={!display} />{button}</>
      } else {
        return null
      }
    }
  )
}

function Gallery({context, name, sizes, galleries, hide=false}) {
  const { gallery } = useGallery(context, name)
  const { content } = useContent(context)
  const { pathname } = useLocation()
  const baseWidth = gallery?.width
  const baseSpacing = gallery?.spacing
  const style = hide ? hiddentStyle : emptyStyle
  const from = useMemo(() => ({location: pathname, context, gallery: name, galleries, title: content?.title}), [context, name, content?.title])
  return <section style={style}>
    {gallery?.layout?.map(slot => <GallerySlot from={from} slot={slot} baseWidth={baseWidth} baseSpacing={baseSpacing} sizes={sizes} />)}
  </section>
}

function placeholder(slot) {
  const div = 50
  const width = Math.round(slot.width/div)
  const height = Math.round(slot.height/div)
  const text = encodeURIComponent(slot.title)
  //return `https://placeimg.com/${width}/${height}?text=${text}`
  return `https://via.placeholder.com/${Math.round(slot.width/div)}x${Math.round(slot.height/div)}?text=${encodeURIComponent(slot.title)}`
}

const ImageContainer = styled('div')`
  margin: 0;
  padding: 0; 
  & img {
    display: block;
  }
`

function GalleryPhoto({slot, from, style, baseWidth, baseSpacing, sizes}) {
  const title = slot.title
  const imgStyle = {
    width: '100%',
    marginBottom: (baseSpacing / slot.width * 100).toFixed(3) + '%'
  } 
  const id = slot.url.slice(1)
  return <ImageContainer style={style} id={id}><Image 
    content={slot.url} 
    style={imgStyle} 
    title={title} 
    width={slot.rawWidth} 
    height={slot.rawHeight}
    sizes={sizes(slot.width/baseWidth)}
    from={from} 
    externalUrl={slot.externalUrl || slot.url}
  /></ImageContainer>
}

const VSplit = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

function GalleryVSplit({slot, baseWidth, from, style, baseSpacing, sizes}) {
  const slots = slot.content.map( 
    s => <GallerySlot slot={s}
      baseWidth={baseWidth} 
      baseSpacing={baseSpacing} 
      parentWidth={slot.width}
      sizes={sizes}
      from={from}
    />
  )
  
  return <VSplit data-s='v' style={style}>{slots}</VSplit>
}

const HSplit = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
function GalleryHSplit({slot, baseWidth, from, style, baseSpacing, sizes}) {
  const slots = slot.content.map( 
    s => <GallerySlot slot={s}
      baseWidth={baseWidth} 
      baseSpacing={baseSpacing} 
      parentWidth={slot.width}
      sizes={sizes}
      from={from}
    />
  )
  return <HSplit data-s='h' style={style}>{slots}</HSplit>
}

const P = styled('p')`
  margin: 0;
  padding: 0.5em;
  &:hover .follow {
    text-shadow: 0 0 1px currentColor;
  }
  & > a {
    color: inherit;
    text-decoration: none;
    &:hover { 
      text-shadow: none;
    }
    &:visited {
      color: inherit;
    }
  }
`
const Follow = styled('span')`
  color: rgb(0,0,128);
  text-decoration: underline dotted;
  &:visited {
    color: rgb(64,0,64);
  }
  &:hover {
    text-shadow: 0 0 1px currentColor;
  }
`

function GalleryText({slot, from}) {
  const spacing = '0.5em' // (baseSpacing / baseWidth * 100).toFixed(3) + '%'
  const id = slot.url.slice(1)
  return <section id={id}>
    <P style={{marginBottom: spacing}}><Link as='a' from={from} href={slot.externalUrl || slot.url}><strong>{slot.title}</strong> — {slot.abstract}… <Follow className="follow">→</Follow></Link></P>
  </section>
}

function GalleryVideo({slot, from, style, baseWidth, baseSpacing, sizes}) {
  return GalleryPhoto({slot, from, style, baseWidth, baseSpacing, sizes})
}

const ProjectType = styled('i')`
  font-family: "Avenir Next","Monserrat Thin","Monserrat","Roboto Thin","Roboto","Helvetica Neue","Segoe UI Light","Segoe UI","Lato Thin","Lato Thin","Corbel Light","Fira Sans Thin","Fira Sans Extra-Light","Open Sans Light","Droid Sans Light", sans-serif;
  font-weight: 200;
  font-style: italic;
`

function GalleryCollection({slot, from}) {
  const spacing = '0.5em' // (baseSpacing / baseWidth * 100).toFixed(3) + '%'
  const id = slot.url.slice(1)
  return <section id={id}>
    <P style={{marginBottom: spacing}}><Link as='a' from={from} href={slot.externalUrl || slot.url}><ProjectType>Collection</ProjectType> ›&nbsp;<strong>{slot.title}</strong> {slot.abstract.match(/\S/) && `— ${slot.abstract}…`} <Follow className="follow">→</Follow></Link></P>
  </section>
}

function GalleryProject({slot}) {
}

function GallerySeries({slot}) {
}

function GalleryHome({slot}) {
}

export function getGalleryPathesFromRatio(context, name, ratio) {
  const keys = (ratio && ratio < 1) ? ["1", "3"] : ["3", "1"]
  const root = context == '/' ? '' : context
  return context && name && keys.map(key => `${root}/gallery/${name}.r${key}`)
}

function useGallery(context, name) {
  const { innerRatio: ratio } = useWindowSize()
  const keys = getGalleryPathesFromRatio(context, name, ratio)
  const enabled = (ratio > 0) && !!context && !!name
  const data = useQuery(keys[0], { enabled, keepPreviousData: true, fallbacks: keys.slice(1)})
  return useMemo(() => ({ gallery: data, loaded: data?.loaded }), [data])
}

function gallerySlots(layouts) {
  return layouts.reduce((acc, cur) => {
    if ((cur.type === 'vsplit') || (cur.type === 'hsplit')) {
      return acc.concat(gallerySlots(cur.content)) 
    } else {
      return acc.concat([cur])
    }
  }, [])
}

export function orderedGallerySlots(gallery) {
  if (!gallery?.layout) return []
  return gallerySlots(gallery.layout).sort((a,b) => a.pos - b.pos)
}

function getPositionIn(current, slots) {
  if (slots) { 
    const index = slots.findIndex(s => s.url === current)
    if (index > -1) {
      const previous = slots[index -1]
      const next = slots[index +1]
      return { position: index + 1, previous, next, total: slots.length }
    } else {
      return { total: slots.length } 
    }
  } else {
    return {}
  }
}

export function useGalleryNavigation({context, name, current, galleries}) {
  current = current.url || current
  const { gallery, loaded } = useGallery(context, name)
  const slots = gallery?.contents
  return useMemo(() => {
    if (!loaded) return { current, context, name }
    const { position, previous, next, total } = getPositionIn(current, slots)
    const index = galleries && galleries.findIndex(n => n === name)
    const nextGallery = galleries && (index !== -1) && galleries[index + 1]
    const previousGallery = galleries && (index > 0) && galleries[index - 1]
    return { position, previous, next, total, current, context, name, nextGallery, previousGallery }
  }, [context, name, current, gallery, galleries, loaded])
}