import { h, Fragment } from 'preact'
import { Link } from '../utils/location'
import { styled } from 'goober'


const labels = {
  project: ['projet', 'projets'],
  series: ['série', 'séries'],
  collection: ['collection', 'collections'],
  photographe: ['photographe', 'photographes'],
  photo: ['photo', 'photos'],
  text: ['texte', 'textes'],
  video: ['vidéo', 'vidéos'],
  home: ['accueil', 'accueil'],
  link: ['lien', 'liens'],
}

const RelatedSection = styled('section')`
& {
  clear: both;
}
& h3 {
  font-family: "Avenir Next","Monserrat Thin","Monserrat","Roboto Thin","Roboto","Helvetica Neue","Segoe UI Light","Segoe UI","Lato Thin","Lato Thin","Corbel Light","Fira Sans Thin","Fira Sans Extra-Light","Open Sans Light","Droid Sans Light", sans-serif;
  font-weight: 200;
  font-size: 1.2em;
  margin-bottom: 0;
  margin-top: 1.5rem;
}
ul {
  margin: 0 0 1em 0;
  padding: 0;
  list-style-type: none;
}
li {
  font-family: "Avenir Next","Monserrat Thin","Monserrat","Roboto Thin","Roboto","Helvetica Neue","Segoe UI Light","Segoe UI","Lato Thin","Lato Thin","Corbel Light","Fira Sans Thin","Fira Sans Extra-Light","Open Sans Light","Droid Sans Light", sans-serif;
  font-weight: 200;
  font-style: italic;
  & a {
    font-family: initial;
    font-weight: initial;
    font-style: initial;
    &:hover {
      text-shadow: 0 0 1px currentColor;
    }
  }
}
`
export function RelatedContent({isIn, seeAlso}) {
  const items = isIn.concat(seeAlso)
  if (items.length === 0) return
  return <RelatedSection>
    <h3>Voir aussi</h3>
    <ul>
      {
        items.map(
          item => {
            return <li>
              {labels[item.type][0][0].toUpperCase() + labels[item.type][0].slice(1)} ›&nbsp;
              <Link href={item.url}>{item.title}</Link>
            </li>
          }
        )
      }
    </ul>
  </RelatedSection>
}

function humanSize(size) {
  if (size < 1000) return "1 Ko"
  if (size < 1000000) return Math.round(size/1000, 0).toString() + " Ko"
  if (size < 5000000) return Math.round(size/1000000, 1).toFixed(1) + " Mo"
  if (size < 1000000000) return Math.round(size/1000000, 0).toString() + " Mo"
  if (size < 5000000000) return Math.round(size/1000000000, 1).toFixed(1) + " Go"
  if (size >= 5000000000) return Math.round(size/1000000000, 0).toString() + " Go"
}

export function AttachedFiles({context}) {
  const hasPdf = context.pdf
  if (hasPdf) {
    const size = context.pdf.size
    return <p><b>Fichier attaché : [<a href={context.url + '.pdf'} target="_blank">PDF</a>, {humanSize(size)}]</b></p>
  }
}