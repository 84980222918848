import { h, Fragment } from 'preact'
import { styled } from 'goober'

const TextSection = styled('section')`
  --size: 1;
  padding: 0 calc(0.5em / var(--size));
  margin: 0 auto calc(0.5em / var(--size)) auto;
  font-size: calc(var(--size) * 1em);
  width: 32em;
  @media (max-width: 34em) {
    width: auto;
  } 
  &:first-child {
    padding-top: calc(0.5em / var(--size));
  }
` 
export default TextSection