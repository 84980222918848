import { h } from 'preact'
import { useMemo } from 'preact/hooks'
import { styled } from 'goober'
import { Link } from '../utils/location'
import { imageWidths, imageUrl } from './image'
import { useWindowSize } from '../utils/window_size'
import { cached } from '../utils/query'
import { dataDir } from '../config'

export function videoUrl(content, size) {
  if (size) return cached(`${dataDir}${content}/video.w${size}.mp4`)
  else return cached(`${dataDir}${content}/video.mp4`)
}

export function Video({content, from, style, title, width, height, availableWidth, availableHeight, url, alt}) {
  url = url ?? content
  const ratio = width / height
  availableHeight = availableHeight ?? height
  availableWidth = Math.min(availableWidth ?? width, availableHeight * ratio)
  style = Object.assign({
    border: 'none',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'block',
    height: 'auto',
    objectFit: 'contain',
    objectPosition: 'center center',
    maxWidth: `min(${availableWidth}px, 100%)`,
  }, style || {})
  const displayableSizes = imageWidths.filter(w => (w < width) && (w >= availableWidth))
  const displayableSize = (displayableSizes.length > 0) ? Math.max(...displayableSizes) : undefined
  const video = <video controls style={style} title={title} poster={imageUrl(content, displayableSize)} alt={alt || ''}>
    <source src={videoUrl(content, displayableSize && 0)} type="video/mp4"/>
  </video>
  return url ? <Link as='a' from={from} href={url}>{video}</Link> : video  
}

const showCaptionOnMainVideo = false
const Figure = styled('figure')`
margin: 0;
padding: 0;
object-fit: contain;
object-position: center center;
`
const Container = styled('div')`
object-fit: contain;
object-position: center center;
margin:auto;
`

const FigCaption = styled('figcaption')`
line-height: 1.3;
font-size: 1rem; 
font-style: italic;
text-align: center;
white-space: nowrap;
`
const mainStyle = { width: '100%', height: '100%' }
export function MainVideo({context, width, height, title, colors, longdesc}) {
  const { innerWidth, innerHeight } = useWindowSize()
  const availableWidth = innerWidth
  const availableHeight = innerHeight
  const style = useMemo(() => ({backgroundColor: colors?.background, color: colors?.text}), [colors?.background, colors?.text])
  if (showCaptionOnMainVideo) availableHeight = availableHeight - 1.3 * 16
  const containerStyle = useMemo(() => showCaptionOnMainVideo ? {height: 'calc(var(--vh, 100vh) - 1.3rem)'} : {height: 'calc(var(--vh, 100vh))'}, [showCaptionOnMainVideo])
  return <Figure style={style}>
    {showCaptionOnMainVideo && <FigCaption>{title}</FigCaption>}
    <Container style={containerStyle}>{context && width && height && <Video 
      content={context} 
      width={width} height={height} 
      title={title} 
      url={false} 
      style={mainStyle}
      alt={longdesc || ''}
      availableWidth={availableWidth} availableHeight={availableHeight}
    />}</Container>
  </Figure>
}
