import { h, createContext } from 'preact'
import debounce from './debounce'
import { useState, useEffect, useContext } from 'preact/hooks'

const WindowSizeContext = createContext('window_size')

export function getSize({height, width, bar}={}) {
  const innerWidth = width || document.documentElement.offsetWidth || window.innerWidth
  const innerHeight = height || window.innerHeight
  const innerRatio = innerWidth / innerHeight
  return {innerWidth, innerHeight, innerRatio, barHeight: bar}
}

export function WithWindowSize({children, height, width, bar}) {
  const [size, setSize] = useState(getSize({height, width, barHeight: bar}))
  useEffect(
    () => {
      document.documentElement.style.setProperty('--vw', innerWidth + 'px')
    }, [size.innerWidth]
  )
  useEffect(
    () => {
      const set = () => { setSize(getSize) }
      const debounced = debounce(set, 100)
      window.addEventListener("resize", debounced, false)
      return () => window.removeEventListener("resize", debounced, false)
    },
    [setSize]
  )
  return <WindowSizeContext.Provider value={size}>{children}</WindowSizeContext.Provider>
}

export function useWindowSize() {
  return useContext(WindowSizeContext)
}

export function setBarHeight() {
  let el = document.createElement('div')
  el.setAttribute('style', 'visibility: hidden; width: 0; height: 100vh; position: absolute; top: 0; left: 0')
  document.body.appendChild(el)
  const vh = el.scrollHeight
  const h = window.innerHeight
  const barSize = vh - h
  document.documentElement.style.setProperty('--bar-height', (vh -h).toFixed(0)+'px')
  document.documentElement.style.setProperty('--vh', 'calc(100vh - var(--bar-height, 0px))')
  document.body.removeChild(el)
  el = undefined
  return { innerHeight: h, bar: barSize}
}