import { h } from 'preact'
import { useMemo } from 'preact/hooks'
import { styled } from 'goober'
import { Link } from '../utils/location'
import { getSize } from '../utils/window_size'
import { cached } from '../utils/query'
import { dataDir } from '../config'

export const imageWidths = [
	8192,
	6144,
	4096, 
	3200, 
	2560, 
	2048, 
	1920, 
	1440, 
	1280, 
	1024, 
	854, 
	720, 
	640, 
	480, 
	360
] 

export function imageUrl(content, size) {
  if (size) return cached(`${dataDir}${content}/img.w${size}.jpg`)
  else return cached(`${dataDir}${content}/img.jpg`)
}

export function prefetchElement({url, width, height}) {
  const browser = getSize()
  const dpr = window.devicePixelRatio || 1
  const available = (browser.innerRatio > (width / height)) ? (browser.innerHeight * width / height) : browser.innerWidth
  const found = imageWidths.filter(w => w < width).reverse().find(w => w >= available * dpr) 
  const image = new window.Image()
  image.src = imageUrl(url, found)
  return image
}

export function Image({content, style, title, width, height, sizes, url, alt, from, externalUrl, fromHash}) {
  url = url ?? content
  sizes = sizes || `(min-aspect-ratio: ${(width).toFixed(0)}/${height}) ${(width / height * 100).toFixed(3)}vh, 100vw`
  style = useMemo(() => Object.assign({
    border: 'none',
    width: 'min(100%, var(--vw, 100vw), calc(95 * var(--vw, 100vw) / ${height} * ${width}))',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'block',
    height: 'auto',
  }, style || {}), [style])
  const availableSizes = imageWidths.filter(w => w < width)
  const srcset = availableSizes.map(s => `${imageUrl(content, s)} ${s}w`).join(', ') 
  const img = <img 
    sizes={sizes}
    srcset={`${imageUrl(content)} ${width}w, ${srcset}`}
    src={imageUrl(content)} 
    style={style} 
    title={title} 
    alt={alt || ''}
    width={width}
    height={height}
  />
  return url ? <Link as='a' fromHash={fromHash} from={from} href={externalUrl || url}>{img}</Link> : img 
}


const showCaptionOnMainImage = false
const Figure = styled('figure')`
margin: 0;
padding: 0;
`
const Container = styled('div')`
object-fit: contain;
object-position: center center;
height: ${() => showCaptionOnMainImage ? 'calc(var(--vh, 100vh) - 1.3rem)' : 'calc(var(--vh, 100vh))'};
`
const FigCaption = styled('figcaption')`
line-height: 1.3;
font-size: 1rem; 
font-style: italic;
text-align: center;
white-space: nowrap;
`
const mainStyle = { width: '100%', height: 'var(--vh, 100%)', objectFit: 'contain', objectPosition: 'center center' }
export function MainImage({context, width, height, title, colors, longdesc}) {
  const style = useMemo(() => ({backgroundColor: colors?.background, color: colors?.text}), [colors?.background, colors?.text])
  return <Figure style={style}>
    {showCaptionOnMainImage && title && <FigCaption>{title}</FigCaption>}
    {colors?.background && <meta name="theme-color" content={colors?.background} />}
    <Container>{width && height && context && <Image content={context} width={width} height={height} title={title} alt={longdesc || ""} url={false} style={mainStyle} />}</Container>
  </Figure>
}