import { h, Fragment } from 'preact'
import { useMemo, useEffect, useRef } from 'preact/hooks'
import { styled } from 'goober'
import { Footer } from '../components/footer'
import { useTitle } from '../utils/title'
import { Galleries } from '../components/gallery'
import { H1 } from '../components/title'
import TextContent from '../components/text'
import TextSection from '../components/sections'
import { RelatedContent, AttachedFiles } from '../components/content'
import { Header, HomeHeader, ImageHeader } from '../components/header'
import { Hero } from '../components/projects'
import useContent from '../utils/content'
import { MainImage } from '../components/image'
import { MainVideo } from '../components/video'
import { useWindowSize } from '../utils/window_size'
import { scrollToHash, useLocation } from '../utils/location'

const Main = styled('main')`
  min-height: calc(var(--vh, 100vh) - 5.5rem); 
`

// TODO gérer la redirection si l'URL demandée et l'URL affichée diffèrent
export default function ContentPage({url}) {
  const { content } = useContent(url)
  const colors = useRef({})
  const pageTitle = (content?.title && content.url !== '/') ? `${content.title} - ` : ''
  useTitle(pageTitle + 'racorps.fr')
  const hasContent = content?.content?.length > 0
  const hasRelated = content?.in?.length > 0 || content?.seeAlso?.length > 0
  const hasGallery = content?.galleries?.length > 0
  const isHome = content?.type === 'home'
  const hasVideo = (content?.type === 'video') || (!isHome && content?.video)
  const hasImage = (content?.type === 'photo') || (!isHome && !hasVideo && content?.image)
  const hasPdf = content?.pdf
  const hasColor = hasImage || hasVideo
  if (content?.colors) colors.current = content.colors
  useEffect(() => {
    if (content.title) scrollToHash()
  }, [url, content.title])
  return <div>
    {hasColor ? <ImageHeader color={hasImage ? colors.current.text : content?.colors?.text} background={hasImage ? colors.current.background : content?.colors?.background} current={content}  /> : (!isHome && <Header current={content} />)}
    <Main>
      {isHome && <Hero />}
      {hasVideo && <MainVideo key={content?.url} longdesc={content?.longdesc} context={content?.url} width={content?.image?.width} height={content?.image?.height} title={content?.title} colors={colors.current} />}
      {hasImage && <MainImage key={content?.url} longdesc={content?.longdesc} context={content?.url} width={content?.image?.width} height={content?.image?.height} title={content?.title} colors={colors.current} />}
      {!isHome && <TextSection>
        {content && (content.externalUrl ? <a href={content.externalUrl} target="_blank"><H1>{content.title}</H1></a> : <H1>{content.title}</H1>)}
        {content && <AttachedFiles context={content} />}
        {hasContent && <TextContent text={content.content} />}
      </TextSection>}
      {hasGallery && <Galleries context={content.url} key={url} />}
      {hasRelated && <TextSection><RelatedContent isIn={content.in} seeAlso={content.seeAlso} /></TextSection>}
    </Main>
    <Footer />
  </div>
}

