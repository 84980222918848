import { h, createContext } from 'preact'
import { useContext, useEffect, useCallback, useState, useMemo } from 'preact/hooks'
import isEqual from './isequal'

const emptyState = {}

const LocationContext = createContext('location')

function relativeUrl(location) {
  return location.pathname + (location.search || "") + (location.hash || "")
}

function hasChanged(previous, next) {
  if (!isEqual(relativeUrl(previous.location), relativeUrl(next.location))) {
    return true
  }
  if (!isEqual(previous.state || emptyState, next.state || emptyState)) {
    return true
  }
  return false
}

function CustomLocationContext({ location, state, children }) {
  const [value, setValue] = useState(() => {
    if (!location) location = new URL(document.location)
    else if (!location.pathname) location = new URL(location)
    state = state || getLocationState()
    const searchParams = new URLSearchParams(location.search)
    const relative = relativeUrl(location)
    return {location, state, searchParams, relative, pathname: location.pathname}
  })
  useEffect(
    () => {
      function onpopstate(event) {
        const location = new URL(document.location)
        const state = event.state || emptyState
        setValue(previous => {
          if (!hasChanged(previous, {location, state})) {
            return previous
          } else {
            const searchParams = new URLSearchParams(location.search)
            const relative = relativeUrl(location)
            return {location, state, searchParams, relative, pathname: location.pathname}
          }
        })
      }
      window.addEventListener('popstate', onpopstate, false)
      return () => window.removeEventListener('popstate', onpopstate, false)
    },[]
  )
  return <LocationContext.Provider value={value}>{children}</LocationContext.Provider>
}

function useLocation() {
  return useContext(LocationContext)
}

function buildNewState({state={}, from, fromHash}) {
  const previousState = getLocationState()
  const shows = previousState.shows
  return (from) ? {...state, from: {shows, hash: fromHash, ...from}} : state
}

function setLocation({url, title, state={}, from, fromHash}) {
  const newState = buildNewState({state, from, fromHash})
  window.history.pushState(newState, title || "racorps.fr", url)
  onChangedLocation(newState)
  window.scrollTo(0, 0)
}

function replaceLocation({url, title, state={}, from, fromHash}) {
  const newState = buildNewState({state, from, fromHash})
  window.history.replaceState(newState, title || window.title, url)
  onChangedLocation(state)
}

function getLocationState() {
  return window.history.state || emptyState
}

function onChangedLocation(state) {
  const event = new PopStateEvent('popstate', { state })
  window.dispatchEvent(event)
}

export function scrollToHash() {
  const hash = document.location.hash
  if (hash.length > 0) {
    const id = hash.slice(1)
    const el = document.getElementById(id)
    if (el) el.scrollIntoView({ block: 'center'})
  }
}

function onClickLink(event, title, state) {
  if (!event.getModifierState("Control") && !event.getModifierState("Meta") && !event.getModifierState("Accel")) {
    event.preventDefault()
    const target = event.currentTarget || event.target
    setLocation({url: target.href, title: title || target.dataset.title, state: state || emptyState})
  }
}

function convertLinks(links, state) {
  // sinon récupérer un événement clic sur window en phase capture,
  // vérifier s'il a un 'a' dans la hiérarchie de target
  // stopImmediatePropagation() & preventDefault()
  const onClick = (event) => onClickLink(event, state)
  links.forEach(link => {
    if (link && !link.dataset?.virtuallink) {
      link.dataset.virtuallink = '2'
      link.addEventListener('click', onClick, false)
    }
  })
  return () => {
    links.forEach(link => {
      if (link && link.dataset?.virtuallink) {
        link.removeEventListener('click', onClick, false)
        delete link.dataset.virtuallink
      }
    })
  }
}

function Link(props) {
  const Tag = props.as || 'a'
  const isExternal = props.href && props.href.match(/^[a-z]+:/)
  const onClick = useCallback(
    (event) => {
      if (props.onClick) props.onClick(event)
      if (!event.getModifierState("Control") && !event.getModifierState("Meta") && !event.getModifierState("Accel")) {
        if (!event.defaultPrevented) {
          event.preventDefault()
          const state = (typeof props.state === 'function') ? props.state(getLocationState) : (props.state || emptyState)
          setLocation({url: event.currentTarget.href || props.href, title: props.title, state, from: props.from, fromHash: props.fromHash})
        }
      }
    }, [props.onClick, props.state, props.from]
  )
  return <Tag {...props} as={undefined} fromHash={undefined} from={undefined} state={undefined} data-virtuallink={'1'} onClick={(isExternal) ? undefined : onClick} target={isExternal ? "_blank" : ""}>{props.children}</Tag>
}

export { Link, relativeUrl, convertLinks, replaceLocation, setLocation, getLocationState, onChangedLocation, useLocation, CustomLocationContext as LocationContext }