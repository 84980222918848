import { h } from 'preact'
import { useQuery } from '../utils/query'
import { styled } from 'goober'
import { Link } from '../utils/location'

const A = styled('a')`
text-decoration: none;
color: inherit;
&:hover {
  text-shadow: 0 0 2px currentColor;
}
`
const UL = styled('ul')`
--root: 1.1;
margin: 2em auto;
padding: 0;
overflow: hidden;
font-size: calc(var(--root, 1) * 1em);
--grid-size: 0.5em;
grid-auto-columns: var(--grid-size);
grid-auto-rows: var(--grid-size);
font-family: "Avenir Next","Monserrat Thin","Monserrat","Roboto Thin","Roboto","Helvetica Neue","Segoe UI Light","Segoe UI","Lato Thin","Lato Thin","Corbel Light","Fira Sans Thin","Fira Sans Extra-Light","Open Sans Light","Droid Sans Light", sans-serif;
font-weight: 200;
@media (max-width: 14em) {
  --root: 0.8;
}
@media (min-width: 19em) {
  --root: 1.1;
  display: grid;
  width: calc(32 * var(--grid-size));
}
@media (min-width: 22.5em) {
  --root: 1.2;
}
@media (min-width: 25em) {
  --root: 1.25;
}
@media (min-width: 30em) {
  --root: 1.5;
}
@media (min-width: 45em) {
  --root: 2;
}
`

const LI = styled('li')`
margin: 0;
padding: 0;
list-style-type: none;
justify-content: center;
display: flex;
align-items: center;
white-space: nowrap;
@media (max-width: 18.9999999em) {
  --def-size: 1em;
}
`
const ROOT = styled('nav')`
padding: 0;
margin: 0;
`

export function Hero() {
  const { projects } = useProjects()
  const usable = projects?.filter(project => project.display)
  return usable && <ROOT>
    <UL>
      {usable.map( project => <HeroItem {...project} /> )}
    </UL>
  </ROOT>
}

function HeroItem({title, url, display}) {
  return <LI style={{
    gridColumn: display.columns.join(' / '),
    gridRow: display.rows.join(' / '),
    fontSize: `var(--def-size, ${display.size}em)`,
  }}><Link as={A} href={url}>{title}</Link></LI>
}

function useProjects() {
  const data = useQuery('/defaults')
  return { projects: data?.projects }
}