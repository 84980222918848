import { useMemo } from 'preact/hooks'
import { useQuery } from '../utils/query'

const initialData = {
  h: { type: 'home', galleries: ['1'] },
  p: { type: 'project', galleries: ['1'] },
  s: { type: 'series', galleries: ['1'] },
  c: { type: 'collection', galleries: ['1'] },
  t: { type: 'text' },
  i: { type: 'photo' },
  v: { type: 'video' },
  f: { type: 'pdf' },
}
function getInitialData(url) {
  return initialData[url[1] || 'h'] || {}
}
export function contentDataUrl(url) {
  return (url=='/' ? '' : url) + '/index'
}
export default function useContent(url) {
  const key = contentDataUrl(url)
  const initialData = useMemo(() => ({ url, ...getInitialData(url) }), [url])
  const data = useQuery(key) || initialData
  return { content: data, key}
}