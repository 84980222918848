import { h, Fragment } from 'preact'
import { resetErrors } from '../utils/query'

// TODO générer un callback pour remplir un fichier de log avec les erreurs
export default function ErrorPage({error, resetError}) {
  return <main style={{margin: '0.5rem'}}>
    <h1>500 - Oops, something went wrong</h1>
    <p>Bon, on ne va pas se mentir, vous ne devriez pas voir cette page, et en plus elle est moche.</p>
    <p>À priori quelque chose a plantouillé et c'est de ma faute. Mes plus plates excuses. Si vous avez le courage, je veux bien un petit email expliquant comment vous êtes arrivé·e là, histoire que je corrige le problème.</p>
    <hr />
<p>Entre temps vous pouvez tenter de {<span dangerouslySetInnerHTML={{__html:`<a onclick="history.back()">revenir en arrière</a>`}} />} ou de <a href="/">revenir à l'accueil</a>.</p>
    <hr />
    <iframe width="560" height="315" src="https://www.youtube.com/embed/CduA0TULnow" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </main>
  return <div style={{padding:'1rem'}}>
    <h1>🚨We have an error</h1>
    <p><button onClick={() => {resetErrors(); resetError()}}><b>&gt;reset&lt;</b></button> <samp>{error.message}</samp></p>
    <pre>{error?.stack}</pre>
  </div>
}